import React, { FC } from 'react';
import { graphql, Link } from 'gatsby';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Layout from 'layout/Layout';
import Image from 'common/Image';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';

import { filterByTagId, nl2br } from '../../utils/functions';
import { AcademyProps } from './models';

import './Academy.scss';

const Academy: FC<{ data: AcademyProps }> = ({
  data: {
    academy: { urls, seo, title, description, imageDesktop, imageMobile, headband },
    global,
    allArticleList: { nodes: articleLists },
    allArticle: { nodes: articles },
  },
}) => {
  const { title: seoTitle, keywords: seoKeywords, description: seoDescription } = seo;

  return (
    <Layout headerTransparent {...{ headband }}>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={seoTitle}
        data={{
          metaTitle: seoTitle,
          metaDescription: seoDescription,
          metaKeywords: seoKeywords,
        }}
      />
      <div className="academy">
        <div className="academy__banner">
          <Image
            imageData={imageDesktop}
            alt={imageDesktop.altText}
            className="d-none d-md-block"
          />
          <Image imageData={imageMobile} alt={imageMobile.altText} className="d-block d-md-none" />
          <div className="academy__banner-text">
            <div className="container-fluid">
              <div className="row">
                <div className="col text-center text-md-start">
                  <h2 className="mb-0 mb-4">{title}</h2>
                  <DangerouslySetInnerHtml html={nl2br(description)} element="p" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="academy__listing">
          {articleLists.map(
            ({
              id,
              title: name,
              description: adescription,
              imageItemDesktop,
              imageItemMobile,
              url,
              tags,
            }) => (
              <div className={`academy__listing-item academy__listing-item__${tags.name}`} key={id}>
                <Image
                  imageData={imageItemDesktop}
                  alt={imageItemDesktop.altText}
                  className="d-none d-md-block"
                />
                <Image
                  imageData={imageItemMobile}
                  alt={imageItemMobile.altText}
                  className="d-block d-md-none"
                />
                <div className="container-fluid academy__listing-content pt-5">
                  <div className="row text-center">
                    <div className="col-12 col-md-6">
                      <h3 className="pt-4 ps-0 ps-md-4 text-md-start">{name}</h3>
                    </div>
                    <div className="col-12 col-md-6">
                      <DangerouslySetInnerHtml
                        html={nl2br(adescription)}
                        element="p"
                        className="pt-1"
                      />
                      <div className="container-fluid px-0 pb-3 academy__listing-articles">
                        <div className="row">
                          {filterByTagId(articles, tags.id, 2).map((article) => (
                            <div className="col-6" key={article.id}>
                              <div
                                className={`academy__listing-article mx-auto pb-2 academy__listing-article__${tags.name}`}
                              >
                                <Link to={article.url} className="d-block">
                                  <Image imageData={article.thumbnail} alt={article.altText} />
                                </Link>
                                <h4 className="pt-2 px-1">{article.title}</h4>
                                <Link to={article.url}>{`${global.read} +`}</Link>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <Link
                        to={url}
                        className={`academy__listing-link py-2 mx-auto d-block mx-md-auto academy__listing-link__${tags.name}`}
                      >
                        {global.allArticles}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($url: String) {
    academy(url: { eq: $url }) {
      urls {
        lang
        href
      }
      headband {
        cta {
          url
          name
        }
        imageDesktop {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
        imageMobile {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      seo {
        ...SEOStructureFragment
      }
      title
      description
      imageDesktop {
        altText
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920)
          }
        }
      }
      imageMobile {
        altText
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 700)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 700)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 700)
          }
        }
      }
    }
    global {
      allArticles
      read
    }
    allArticle {
      nodes {
        id
        title
        url
        tags {
          id
          name
        }
        thumbnail {
          altText
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 220)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 220)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 220)
            }
          }
        }
      }
    }
    allArticleList {
      nodes {
        id
        title
        description
        url
        tags {
          id
          name
        }
        imageItemDesktop {
          altText
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 1920)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 1920)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 1920)
            }
          }
        }
        imageItemMobile {
          altText
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 700)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 700)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 700)
            }
          }
        }
      }
    }
  }
`;

export default Academy;
